<template>
  <label
    class="response-rating-scale-option"
    :class="classes"
    :disabled="disabled"
    @animationend="animate = false"
  >
    <input
      v-model="model"
      class="input"
      type="radio"
      :disabled="disabled"
      :name="name"
      :value="value"
      :checked="checked"
      @click="onClick"
    >
    <div class="content">
      <slot>{{ label }}</slot>
    </div>
    <div class="badge">
      <base-icon
        name="checkmark-outline"
        :size="16"
      />
    </div>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    checked: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        // The value must match one of these strings
        return ['large', 'medium', 'small'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      animate: false,
    };
  },
  computed: {
    model: {
      get() { return this.checked; },
      set(value) { this.$emit('input', value); },
    },
    isChecked() {
      return this.value === this.checked;
    },
    classes() {
      return [
        { 'response-rating-scale-option--checked': this.isChecked },
        { 'response-rating-scale-option--disabled': this.disabled },
        { 'response-rating-scale-option--animate': this.animate },
        `response-rating-scale-option--${this.size}`,
      ];
    },
  },
  watch: {
    isChecked(checked) {
      if (checked) this.animate = true;
    },
  },
  methods: {
    onClick() {
      // Emit click event
      this.$emit('click');
      // If the option is already checked, force animation
      if (this.checked) {
        // Trigger animation
        this.animate = true;
        window.setTimeout(() => {
          this.animate = false;
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.response-rating-scale-option {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  margin-bottom: 8px;
  margin-left: 4px;
  color: $color-white;
  font-size: 0;
  font-size: 14px;
  background-color: rgba($color-white, 0.2);
  // border: solid 1px $color-primary5;
  border-radius: 60px;
  cursor: pointer;
  transition: background-color, border-color, color, transform;
  transition-timing-function: ease;
  transition-duration: 200ms;
  user-select: none;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &--checked {
    color: $color-text;
    background-color: $color-white;
    border-color: $color-white;

    .badge {
      opacity: 1;
      transition: opacity 100ms ease;
    }
  }

  &--disabled {
    color: $color-gray50;
    background-color: $color-gray50;
    cursor: auto;
    pointer-events: none;
  }

  &--animate {
    animation: zoom-in 300ms ease;
  }

  &--small {
    width: 50px;
    height: 50px;

    .badge {
      width: 16px;
      height: 16px;
    }
  }

  &--medium {
    width: 60px;
    height: 60px;

    .badge {
      width: 18px;
      height: 18px;
    }
  }

  &--large {
    width: 80px;
    height: 80px;

    .badge {
      width: 20px;
      height: 20px;
    }
  }
}

.content {
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.badge {
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  line-height: 0;
  background-color: $color-success;
  border: solid 2px #fff;
  border-radius: 15px;
  box-shadow: 0 2px 3px rgba($color-primary90, 0.15);
  opacity: 0;
  transition: opacity 50ms ease;
}

.input {
  display: none;
}

.label {
  pointer-events: none;
}

@keyframes zoom-in {
  0% { transform: scale(1); }
  20% { transform: scale(1.15); }
  100% { transform: scale(1); }
}
</style>
