<template>
  <base-page
    class="intake"
    :loading="isloading"
    type="dark"
    fullscreen
  >
    <template #menu>
      <base-button
        v-if="isAuthenticated || error"
        class="button"
        label="Return"
        :to="{ name: 'home' }"
        size="small"
        type="ghost-inverted"
        icon="arrow-back-outline"
      />
    </template>
    <template #default>
      <!-- ERROR STATE -->
      <base-graphic
        v-if="error"
        :image="require('@/assets/images/graphic/waiting.svg')"
        :title="error.title"
        :body="error.message"
      />
      <!-- DEFAULT STATE -->
      <template v-else-if="categories">
        <base-tag
          class="category"
          type="secondary"
        >
          Intake
        </base-tag>
        <h1 class="question">
          How well does your company score when it comes to ...
        </h1>
        <div class="details">
          <transition-fade-zoom>
            <div
              :key="currentCategory.name"
              class="subject"
            >
              {{ currentCategory.name }}
            </div>
          </transition-fade-zoom>
          <div class="progress">
            {{ index + 1 }} out of {{ totalCategories }}
          </div>
        </div>
        <div
          class="response"
          :class="{ 'response--locked': locked }"
        >
          <response-rating-scale-3-point
            v-model="answers[index]"
            :categories="categories"
            :disabled="locked"
            @click="onAnswerClick"
          />
        </div>

        <base-group
          class="footer"
          align="center"
          spacing="medium"
          direction="vertical"
          vertical-align="bottom"
        >
          <base-button
            v-if="isFinalCategory && hasAnswered"
            class="submit"
            :label="isFinalCategory ? 'Send' : 'Please answer to continue'"
            type="secondary"
            size="large"
            :loading="isloading"
            @click="onSubmitClick"
          />
          <base-button
            v-if="index > 0"
            class="back"
            label="Previous"
            type="ghost-inverted"
            link
            @click="onPreviousClick"
          />
        </base-group>
      </template>
    </template>
  </base-page>
</template>

<script>
import ResponseRatingScale3Point from '@/components/response/ResponseRatingScale3Point.vue';

export default {
  components: { ResponseRatingScale3Point },
  data() {
    return {
      locked: false,
      index: 0,
      answers: [],
      error: null,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    isLoading() {
      return this.$store.getters['intake/isLoading'];
    },
    categories() {
      return this.$store.getters['intake/getCategories'];
    },
    totalCategories() {
      return this.categories.length;
    },
    currentCategory() {
      return this.categories[this.index];
    },
    currentAnswer() {
      return this.answers[this.index];
    },
    hasAnswered() {
      return !!this.currentAnswer;
    },
    isFinalCategory() {
      return this.index === this.categories.length - 1;
    },
  },
  mounted() {
    this.saveKey();
    this.getCategories();
  },
  methods: {
    saveKey() {
      this.$store.commit('intake/SET_KEY', this.$route.query.key);
    },
    getCategories() {
      this.$store.dispatch('intake/fetchIntake').catch(() => {
        // Fetching categories failed
        this.error = {
          title: 'Intake not found',
          message: 'Maybe you have already answered the intake questions before?',
        };
      });
    },
    submit() {
      // Create answers response
      const answers = this.getAnswers();
      // Submit answers
      this.$store.dispatch('intake/submitIntake', answers).then(() => {
        // Refresh data
        this.$emit('refresh');
        // Move to responded route
        this.$router.push({ name: 'responded' });
      }).catch(() => {
        // Submitting answer failed
        this.error = {
          title: 'Response failed',
          message: 'Your response could not be saved. Please try again!',
        };
      });
    },
    onAnswerClick() {
      // Disable question input
      this.locked = true;
      setTimeout(() => {
        if (!this.isFinalCategory) {
        // Move to new question
          this.index += 1;
        }
        // Allow question input again
        this.locked = false;
      }, 500);
    },
    onPreviousClick() {
      if (this.index === 0) return;
      // Reset current answer
      this.answers.splice(this.index, 1);
      window.setTimeout(() => {
        // Move to previous category
        this.index -= 1;
      }, 1);
    },
    onSubmitClick() {
      if (!this.hasAnswered) return;
      // Submit answers
      this.submit();
    },
    getAnswers() {
      const answers = {};
      for (let i = 0; i < this.categories.length; i += 1) {
        answers[this.categories[i].id] = this.answers[i];
      }
      return answers;
    },
  },
};
</script>

<style lang="scss" scoped>
.intake {
  display: flex;
  flex-direction: column;
}

.category {
  flex-shrink: 0;
  align-self: flex-start;
  margin-bottom: 16px;
}

.question {
  margin: 0 0 24px;
  font-weight: bold;
  font-size: 32px;
  font-family: $font-family-title;
  line-height: 1.1em;
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.subject {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-bottom: 16px;
  padding: 0 24px;
  font-weight: bold;
  font-size: 20px;
  font-family: $font-family-title;
  background-color: $color-primary;
  border-radius: 50px;
}

.progress {
  display: flex;
  align-items: center;
  align-self: center;
  height: 30px;
  padding: 0 12px;
  font-weight: bold;
  font-size: 12px;
  background-color: rgba($color-white, 0.2);
  border-radius: 30px;
}

.response {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;

  &--locked {
    cursor: auto;
    pointer-events: none;
  }
}

.footer {
  min-height: 120px;
}
</style>
