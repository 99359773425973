<template>
  <div class="response-slider">
    <input
      v-model="model"
      type="range"
      class="input"
      :min="min"
      :max="max"
    >
    <div class="thumb-track">
      <div
        ref="thumb"
        class="thumb"
      >
        <base-icon
          class="icon"
          name="swap-outline"
          :size="20"
        />
      </div>
    </div>
    <div class="track" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    model: {
      get() { return this.value; },
      set(value) { this.$emit('input', parseInt(value, 10)); },
    },
  },
  watch: {
    value() {
      this.updateThumb();
    },
  },
  mounted() {
    this.updateThumb();
  },
  methods: {
    updateThumb() {
      // Get current value or else place the slider in the middle
      const value = this.value || (this.max - this.min) / 2 + this.min;
      const percentage = ((value - this.min) * 100) / (this.max - this.min);
      this.$refs.thumb.style.left = `${percentage}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.response-slider {
  position: relative;
  font-size: 0;
  pointer-events: none;
}

.thumb-track {
  position: absolute;
  top: 0;
  right: 20px;
  left: 20px;
}

.thumb {
  position: absolute;
  top: -4px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  color: $color-primary;
  line-height: 0;
  background-color: $color-white;
  border-radius: 100%;
  box-shadow: 0 0 0 0 $color-white, 0 0 0 6px #3b3166;
  transform: rotate(-45deg);
  transition: border-radius, box-shadow;
  transition-timing-function: ease;
  transition-duration: 200ms;
}

.icon {
  transform: rotate(45deg);
}

.track {
  position: absolute;
  top: 14px;
  right: 10px;
  left: 10px;
  z-index: 1;
  height: 6px;
  background: rgba($color-white, 0.1);
  border-radius: 8px;
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-appearance: none;
  pointer-events: auto;
}

.input:focus {
  outline: none;
}

.input::-webkit-slider-thumb {
  position: relative;
  width: 40px;
  height: 40px;
  margin-top: 0;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0;
  -webkit-appearance: none;
}

.input:active::-webkit-slider-thumb {
  border-radius: 80% 30% 55% 50% / 55% 30% 80% 50%;
  box-shadow: 0 0 0 3px $color-white, 0 0 0 8px #3b3166;
  transition-timing-function: cubic-bezier(0.34, 1.365, 0.675, 1.005);
  transition-duration: 100ms;
}

.input:active + .thumb-track .thumb {
  border-radius: 80% 30% 55% 50% / 55% 30% 80% 50%;
  box-shadow: 0 0 0 3px $color-white, 0 0 0 8px #3b3166;
  transition-timing-function: cubic-bezier(0.34, 1.365, 0.675, 1.005);
  transition-duration: 100ms;
}
</style>
