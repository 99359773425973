<template>
  <div class="response-rating-scale3">
    <div class="options">
      <response-rating-scale-option
        v-for="option in options"
        :key="option.value"
        v-model="selected"
        class="option"
        :value="option.value"
        :checked="option.value === selected"
        :name="name"
        size="large"
        @click="$emit('click')"
      >
        <img
          class="option-image"
          :src="require('@/assets/images/emoji/' + option.name + '.png')"
          :srcset="require('@/assets/images/emoji/' + option.name + '.png') + ' 1x,'
            + require('@/assets/images/emoji/' + option.name + '@2x.png') + ' 2x,'
            + require('@/assets/images/emoji/' + option.name + '@3x.png') + ' 3x'"
          :alt="option.value"
        >
      </response-rating-scale-option>
    </div>
    <div
      v-if="slider"
      class="slider"
    >
      <response-slider
        v-model="selected"
        :min="1"
        :max="options.length"
      />
    </div>
  </div>
</template>

<script>
import ResponseSlider from '@/components/response/ResponseSlider.vue';
import ResponseRatingScaleOption from '@/components/response/ResponseRatingScaleOption.vue';

export default {
  components: {
    ResponseSlider,
    ResponseRatingScaleOption,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    answers: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    slider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [
        { value: 1, name: 'thumbs-down' },
        { value: 2, name: 'person-shrugging' },
        { value: 3, name: 'thumbs-up' },
      ],
      selected: null,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.selected = value;
      },
      immediate: true,
    },
    selected(selected) {
      this.$emit('input', selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.response-rating-scale3 {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.options {
  display: flex;
  justify-content: space-between;
  margin: 32px 0 24px;
}

.slider {
  margin-bottom: 48px;
  padding: 0 8px;
}

.option-image {
  width: 40px;
}
</style>
